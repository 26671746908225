import * as React from "react";
import Layout from "@components/layout";
import PartnersWorking from "@images/partners-working.png";

const AboutPage = () => {
	return (
		<Layout pageTitle="About" headerBg="bg-purple-50">
			<section className="relative md:pb-24 lg:pb-22 overflow-hidden bg-purple-50">
				<div className="relative lg:pt-16 pb-28">
					<div className="container px-4 mx-auto mt-32 mb-4 text-center">
						<h2 className="mt-8 mb-8 text-3xl lg:text-4xl font-semibold">
							Fast, secure, reliable payments.
						</h2>
						<p className="max-w-3xl mx-auto mb-5 text-xl text-gray-700">
							We are founded on the belief that financial transactions should be
							easy, secure, and accessible to everyone, regardless of their
							location or financial situation.
						</p>
					</div>
				</div>
			</section>

			<section class="pt-28 pb-20 bg-white overflow-hidden">
				<div class="container px-4 mx-auto">
					<h2 class="mb-20 text-5xl lg:text-6xl font-bold font-heading tracking-px-n leading-none">
						The difference is clear.
					</h2>
					<div class="flex flex-wrap xl:items-center -m-8 xl:-m-10">
						<div class="w-full md:w-1/2 xl:w-auto p-8 xl:p-10">
							<a class="block overflow-hidden rounded-3xl" href="#">
								<img
									class="w-full transform hover:scale-105 transition ease-in-out duration-1000"
									src={PartnersWorking}
									alt=""
								/>
							</a>
						</div>
						<div class="w-full md:w-1/2 xl:flex-1 p-8 xl:p-10">
							<div class="md:max-w-xl">
								<div class="mb-4 pb-8">
									<h3 class="text-lg md:max-w-lg">
										We provide a wide range of payment solutions to individuals
										and businesses worldwide. Our services include: <br />
										<br />
									</h3>
									<ul>
										<li className="mb-4 gap-2 flex items-center">
											<svg
												className="mr-1"
												width="30"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path
													d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
													stroke="#4F46E5"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"></path>
											</svg>
											<p className=" font-semibold leading-normal">
												Creating and funding virtual cards
											</p>
										</li>
										<li className="mb-4 gap-2 flex items-center">
											<svg
												className="mr-1"
												width="30"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path
													d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
													stroke="#4F46E5"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"></path>
											</svg>
											<p className=" font-semibold leading-normal">
												Bill payments.
											</p>
										</li>
										<li className="mb-4 gap-2 flex items-center">
											<svg
												className="mr-1"
												width="30"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path
													d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
													stroke="#4F46E5"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"></path>
											</svg>
											<p className=" font-semibold leading-normal">
												Gift cards,
											</p>
										</li>
										<li className="mb-4 gap-2 flex items-center">
											<svg
												className="mr-1"
												width="30"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path
													d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
													stroke="#4F46E5"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"></path>
											</svg>
											<p className=" font-semibold leading-normal">
												Airtime and Data top-up
											</p>
										</li>
									</ul>
									<h3 class="text-lg md:max-w-lg mt-5">
										Our team of experts works tirelessly to provide cutting-edge
										payment solutions to individuals and businesses worldwide,
										and we are proud to serve customers in multiple countries
										and regions.
									</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="pt-32 pb-36 bg-indigo-50 overflow-hidden">
				<div class="container px-4 mx-auto">
					<div class="md:max-w-2xl">
						<h2 class="mb-9 text-5xl lg:text-6xl font-bold tracking-px-n leading-none">
							Quality over Quantity
						</h2>
						<p class="mb-12 text-opacity-70">
							At Platnova, we are committed to maintaining the highest standards
							of security, transparency, and ethics in all our operations.
							<br />
							<br /> We leverage the latest technology and our extensive
							industry experience to provide our customers with the easiest,
							most secure, and efficient payment processing services possible.
						</p>
					</div>
					<div class="flex flex-wrap -m-3">
						<div class="w-full md:w-1/2 p-3">
							<div class="p-7 bg-white border border-gray-100 rounded-3xl">
								<div class="flex flex-wrap -m-4">
									<div class="w-auto p-4">
										<svg
											width="28"
											height="28"
											viewbox="0 0 28 28"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M4.66669 5.83329C4.66669 5.18896 5.18902 4.66663 5.83335 4.66663H22.1667C22.811 4.66663 23.3334 5.18896 23.3334 5.83329V8.16663C23.3334 8.81096 22.811 9.33329 22.1667 9.33329H5.83335C5.18902 9.33329 4.66669 8.81096 4.66669 8.16663V5.83329Z"
												stroke="#6366F1"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"></path>
											<path
												d="M4.66669 15.1666C4.66669 14.5223 5.18902 14 5.83335 14H12.8334C13.4777 14 14 14.5223 14 15.1666V22.1666C14 22.811 13.4777 23.3333 12.8334 23.3333H5.83335C5.18902 23.3333 4.66669 22.811 4.66669 22.1666V15.1666Z"
												stroke="#6366F1"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"></path>
											<path
												d="M18.6667 15.1666C18.6667 14.5223 19.189 14 19.8334 14H22.1667C22.811 14 23.3334 14.5223 23.3334 15.1666V22.1666C23.3334 22.811 22.811 23.3333 22.1667 23.3333H19.8334C19.189 23.3333 18.6667 22.811 18.6667 22.1666V15.1666Z"
												stroke="#6366F1"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"></path>
										</svg>
									</div>
									<div class="flex-1 p-4">
										<h3 class="mb-3 text-lg font-semibold">Mission</h3>
										<p class="text-gray-600 font-medium pb-6">
											Our mission is to empower individuals and businesses
											worldwide by providing them with the easiest, most secure,
											and efficient payment processing services. We strive to
											create a world where financial transactions are seamless,
											enabling everyone to achieve their dreams and goals.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="w-full md:w-1/2 p-3">
							<div class="p-7 bg-white border border-gray-100 rounded-3xl">
								<div class="flex flex-wrap -m-4">
									<div class="w-auto p-4">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke-width="1.5"
											stroke="currentColor"
											class="w-6 h-6">
											<path
												stroke-linecap="round"
												stroke="#6366F1"
												stroke-width="2"
												stroke-linejoin="round"
												d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
											/>
										</svg>
									</div>
									<div class="flex-1 p-4">
										<h3 class="mb-3 text-lg font-semibold">Vision</h3>
										<p class="text-gray-600 font-medium">
											Our vision is to become the most trusted and innovative
											payment processing company in the world. We envision a
											future where anyone, anywhere, can make financial
											transactions with ease, speed, and security. By leveraging
											the latest technology and our commitment to excellence, we
											will revolutionize the world of finance and empower
											millions of people to achieve their goals.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default AboutPage;
